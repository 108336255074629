import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import { withPage } from "../../Page";
import ArticleView from "../../Components/ArticleView";
import labels from "../../Domain/ArticleTree";
import FilterPanel from "../../Components/FilterPanel";

class ArticleDetailPage extends React.Component {
  state = {
    item: null
  };

  componentDidMount() {
    let { appActions, id } = this.props;
    appActions
      .fetchArticleById(id)
      .then(item => {
        this.setState({ item });
      })
      .catch(err => {
        // TODO: handle for 404 and api failure
      });
  }

  render() {
    let { navActions } = this.props;
    let { item } = this.state;

    return (
      <Wrapper>
        <div className="article-filter">
          <FilterPanel
            labels={labels}
            activeFilter={""}
            onFilterItemClick={updateLabel =>
              navActions.push(`/articles/${updateLabel}`)
            }
          />
        </div>

        <div className="article-detail">
          <ArticleView item={item} />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;

  & > .article-filter {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  & > .article-detail {
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      id: Selectors.getQueryParams(ownProps).id
    }),
    ActionCreator
  )(ArticleDetailPage)
);
